import { cva, type VariantProps } from "./style.utils";

export type DotVariants = VariantProps<typeof dotVariants>;

export const dotVariants = cva({
  base: "",
  variants: {
    size: {
      xs: "size-4px",
      sm: "size-8px",
      md: "size-10px",
      lg: "size-13px",
    },
    palette: {
      transparent: "text-transparent",
      white: "text-white",
      black: "text-black",
      info: "text-info-100",
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-100",
      neutral: "text-neutral-100",
      primary: "text-primary-100",
      secondary: "text-secondary-100",
      gain: "text-gain",
      loss: "text-loss",
      "chart-blue": "text-chart-blue-100",
      "chart-green": "text-chart-green-100",
      "chart-teal": "text-chart-teal-100",
      "chart-orange": "text-chart-orange-100",
      "chart-purple": "text-chart-purple-100",
      "chart-gray": "text-chart-gray-100",
      "chart-yellow": "text-chart-yellow-100",
      "chart-lime": "text-chart-lime-100",
    },
  },
  defaultVariants: {
    size: "md",
    palette: "neutral",
  },
});
