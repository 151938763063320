"use client";

import { type GetNotificationData } from "#app/_api/external-bol-notification-service/get-notification";
import { Button } from "#ui/button";
import {
  Dialog,
  DialogClose,
  DialogCloseIconButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogMain,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  type DialogProps,
  type DialogTriggerProps,
} from "#ui/dialog";
import { P } from "#ui/typography";
import { MyUpdatesDateTime } from "./MyUpdatesDateTime";
import { MyUpdatesIcon } from "./MyUpdatesIcon";

export function MyUpdatesDialogRead({
  children,
  data,
  ...props
}: Omit<DialogProps, "children" | "onOpenChange" | "open"> & {
  children: DialogTriggerProps["children"];
  data: Required<
    Pick<
      GetNotificationData[number],
      "created" | "icon" | "longMessage" | "shortMessage"
    >
  >;
}) {
  return (
    <Dialog {...props}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent variant="center-scroll-inner">
          <DialogHeader>
            <DialogTitle>About this update</DialogTitle>
          </DialogHeader>
          <DialogMain>
            <div className="flex flex-nowrap gap-15px text-left">
              <MyUpdatesIcon icon={data.icon} />
              <div className="flex-1 space-y-14px">
                <div>
                  <DialogDescription>
                    {data.shortMessage ?? ""}
                  </DialogDescription>
                  <MyUpdatesDateTime value={data.created} />
                </div>
                {data.longMessage ? <P size="sm">{data.longMessage}</P> : null}
              </div>
            </div>
            <DialogCloseIconButton />
          </DialogMain>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
