import { patch, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type PatchNotificationParams = {
  body: {
    updateId: string;
    typeId: number;
    isRead: boolean;
  };
};

export async function patchNotification(
  params: PatchNotificationParams & Pick<Options, "signal">,
): Promise<void> {
  // Call endpoint
  await patch(`${GATEWAY_PATHS.externalBolNotificationService}/Notification`, {
    json: params.body,
    signal: params.signal,
  });
}
