import { remove, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type DeleteNotificationParams = {
  body: {
    updateId: string;
    typeId: number;
  };
};

export async function deleteNotification(
  params: DeleteNotificationParams & Pick<Options, "signal">,
): Promise<void> {
  // Call endpoint
  await remove(`${GATEWAY_PATHS.externalBolNotificationService}/Notification`, {
    json: params.body,
    signal: params.signal,
  });
}
