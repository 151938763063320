import { P } from "#ui/typography";
import Image from "next/image";

export function MyUpdatesEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        width="420"
        height="321"
        className="mx-auto w-148px sm:w-211px"
        priority
      />
      <P size="h6">You have no updates right now.</P>
    </div>
  );
}
