"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import { deleteNotification } from "#app/_api/external-bol-notification-service/delete-notification";
import { type GetNotificationData } from "#app/_api/external-bol-notification-service/get-notification";
import { logError } from "#app/lib/logger";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  type AlertDialogTriggerProps,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export function MyUpdatesDialogDelete({
  children,
  data,
}: {
  children: AlertDialogTriggerProps["children"];
  data: Required<Pick<GetNotificationData[number], "typeId" | "updateId">>;
}) {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const { queryKey } =
    allQueryKeys.externalBolNotificationService.notification();

  const mutation = useMutation({
    mutationFn: deleteNotification,
    // When mutate is called:
    onMutate: async (params) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const prevData = queryClient.getQueryData(
        queryKey,
      ) as GetNotificationData;

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        prevData.filter((t) => t.updateId !== params.body.updateId),
      );

      // Return a context object with the snapshotted value
      return { prevData };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _params, context) => {
      logError(err);
      queryClient.setQueryData(queryKey, context?.prevData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          variant="center-scroll-inner"
          className="max-w-450px gap-30px p-30px pt-50px"
        >
          <AlertDialogMain className="p-0">
            <div className="flex flex-col items-center space-y-14px text-center">
              <Icon name="baird-close-circle" size="baird-lg" palette="error" />
              <AlertDialogTitle className="font-semibold">
                Are you sure you want to delete this update?
              </AlertDialogTitle>
              <AlertDialogDescription className="text-sm font-normal text-shade-70">
                This cannot be undone.
              </AlertDialogDescription>
            </div>
            <AlertDialogCancel asChild>
              <AlertDialogCloseIconButton />
            </AlertDialogCancel>
          </AlertDialogMain>
          <AlertDialogFooter className="m-0 border-t-0 p-0">
            <Button
              onClick={() =>
                mutation.mutate(
                  { body: { typeId: data.typeId, updateId: data.updateId } },
                  { onSettled: () => setOpen(false) },
                )
              }
              pending={mutation.isPending}
              palette="error"
              className="order-last"
            >
              Delete
            </Button>
            <AlertDialogCancel asChild>
              <Button variant="outline">Cancel</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
